//Sending messages from JavaScript to C#
export function vuplexMessage(type, msg, content = "") {
  const data = {
    type: type,
    message: msg,
    content: content,
  };
  if (window.vuplex) {
    window.vuplex.postMessage(data);
    console.log("window.vuplex 已发送内容：", data);
  } else {
    console.log("window.vuplex 不存在", data);
  }
}
