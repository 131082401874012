<template>
  <div class="login-container">
    <div class="card">
      <div class="title">输入患者信息</div>
      <div class="userinfo">
        {{ username }}，您好！<span class="login_span" @click="login_out"
          >退出</span
        >
      </div>
      <div class="form">
        <el-form
          ref="loginFormRef"
          class="demo-loginForm"
          :model="form"
          status-icon
        >
          <el-form-item prop="username">
            <el-autocomplete
              v-model="form.username"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入患者姓名"
              size="large"
              style="width: 100%"
              :trigger-on-focus="false"
              value-key="name"
              @select="handleSelect"
              @blur="input_blur"
              @focus="input_foucs"
            />
          </el-form-item>
          <el-form-item prop="uid">
            <el-input
              v-model="form.uid"
              disabled
              placeholder="患者编号"
              size="large"
            />
          </el-form-item>
          <el-form-item>
            <div class="btn" @click="save">确认</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/patient";
import { vuplexMessage } from "@/utils/vuplex";
export default {
  name: "ChoosePatient",
  data() {
    return {
      formRef: null,
      form: {
        username: "",
        uid: "",
      },
    };
  },
  mounted() {},
  computed: {
    username() {
      return this.$store.state.userName;
    },
  },
  methods: {
    async querySearchAsync(queryString, cb) {
      const {
        data: { list },
      } = await getList({ name: queryString, pageNo: 1, pageSize: 20 });
      if (list.length == 0) {
        this.$message.error(
          "没检索到相应患者，请修改检索关键词或到后台添加该患者"
        );
      }
      cb(list);
    },
    handleSelect(item) {
      this.form.uid = item.id;
    },
    async save() {
      if (this.form.uid == "") {
        this.$message.error("请输入患者姓名选择对应患者");
        return;
      }
      await this.$router.push({
        path: "/",
        query: { uid: this.form.uid },
      });
    },
    //退出
    async login_out() {
      this.$store.dispatch("resetAll");
      this.$message.success("退出成功");
      await this.$router.push({
        path: "/login",
        replace: true,
      });
    },
    input_blur() {
      vuplexMessage("Keyboard", "EndInput", this.form.username);
    },
    input_foucs() {
      vuplexMessage("Keyboard", "StartInput", this.form.username);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1024px;
  height: 1440px;
  background-image: url(../../assets/login_bg.jpg);
}
.title {
  margin-top: 120px;
  font-size: 60px;
  font-weight: bold;
  line-height: 127px;
  color: #078599;
}
.userinfo {
  font-size: 30px;
}
.card {
  position: relative;
  width: 689px;
  height: 926px;
  margin: 0px auto;
  text-align: center;
  background: #ffffff;
}
.form {
  width: 438px;
  margin: 0px auto;
  margin-top: 123px;
}

.btn {
  width: 282px;
  height: 78px;
  margin: 0px auto;
  margin-top: 90px;
  font-size: 36px;
  font-weight: 400;
  line-height: 78px;
  color: #ffffff;
  cursor: pointer;
  background: #078599;
  border-radius: 5px;
}
.login_span {
  color: #078599;
  cursor: pointer;
}
</style>
